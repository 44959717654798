import { t } from "i18next";
import { QRCodeSVG } from "qrcode.react";
import React, { useRef, useState } from "react";
import { Trans } from "react-i18next";

import toggleStagingOnTurn from "assets/toggleStagingOnTurn.png";
import toggleStagingOnWA from "assets/toggleStagingOnWA.png";
import { TurnLogoBase64 } from "components/shared/qr/logo";
import { BorderColorDefault } from "ds/Alias";
import { Box } from "ds/Box";
import { Button } from "ds/Button";
import { BorderRadius200, BorderRadius300, ShadowHigh, Space300, Space400 } from "ds/Core";
import { Modal } from "ds/Modal";
import { Tooltip } from "ds/Tooltip";
import { Heading, Text } from "ds/Typography";
import { Icon } from "ds/icons";
import { TInitialNumber } from "graphqlQueries";
import { useNavigation } from "hooks";
import { Anchor } from "lib/impact";
import { turnTheme } from "theme";
import { numberText, numberWaMeUrl } from "utils";
import { downloadSvg } from "utils/download";
import { WhatsAppQRCodeLink } from "utils/links";

type PreviewOnWhatsApp = {
  previewOnWA: boolean;
  setPreviewOnWA: React.Dispatch<React.SetStateAction<boolean>>;
  number: TInitialNumber;
};

export const PreviewOnWhatsApp = ({ previewOnWA, setPreviewOnWA, number }) => {
  const { getPath } = useNavigation();
  const [previewChanges, setPreviewChanges] = useState<"public" | "unpublished">("public");
  const svgRef = useRef<SVGSVGElement>(null);

  const handleQrDownload = () => {
    const svg = svgRef.current;
    if (svg) {
      downloadSvg(svg, `QR Code ${numberText(number)}.svg`);
    }
  };

  return (
    <Modal
      type="STEPS"
      visible={previewOnWA}
      onHide={() => setPreviewOnWA(false)}
      style={{ width: "740px" }}
      contentProps={{ style: { marginTop: 0 } }}
    >
      <Box flexDirection="row" mb={Space400}>
        <Box
          width="100%"
          py="100"
          alignItems="center"
          cursor="pointer"
          borderBottom={previewChanges === "public" ? "2px solid" : "1px solid"}
          borderBottomColor={previewChanges === "public" ? "black" : "borderColorDefault"}
          onClick={() => setPreviewChanges("public")}
        >
          <Text preset="paragraph1-200" color={previewChanges === "public" ? undefined : "textColorSubtle"}>
            {t("build.stack.canvas.staging.public-version")}
          </Text>
        </Box>
        <Box
          width="100%"
          py="100"
          alignItems="center"
          cursor="pointer"
          borderBottom={previewChanges === "unpublished" ? "2px solid" : "1px solid"}
          borderBottomColor={previewChanges === "unpublished" ? "black" : "borderColorDefault"}
          onClick={() => setPreviewChanges("unpublished")}
        >
          <Text preset="paragraph1-200" color={previewChanges === "unpublished" ? undefined : "textColorSubtle"}>
            {t("build.stack.canvas.staging.unpublished-changes")}
          </Text>
        </Box>
      </Box>

      <Box flexDirection="row" gap="300">
        {previewChanges === "public" ? (
          <>
            <Box
              width={330}
              height={376}
              borderRadius={BorderRadius300}
              p={Space300}
              backgroundColor="backgroundColorGray"
              gap={Space300}
            >
              <img width={72} height={72} src={number?.profilePicture || ""} alt="" />
              <Heading level="h2">{number?.vname}</Heading>

              <Box mt="auto" gap="100">
                <Text preset="paragraph1-100">
                  {t("build.stack.canvas.staging.number", { number: number?.fromAddr })}
                </Text>
                <Text preset="paragraph1-100">
                  <Trans
                    i18nKey="build.stack.canvas.staging.wame-link"
                    values={{ wameLink: numberWaMeUrl(number) }}
                    components={{
                      a: (
                        <Anchor
                          target="_blank"
                          rel="noreferrer"
                          href={numberWaMeUrl(number)}
                          decoration="underline"
                        ></Anchor>
                      ),
                    }}
                  />
                </Text>
              </Box>
            </Box>

            <Box
              width={330}
              height={376}
              borderRadius={BorderRadius300}
              p={Space300}
              backgroundColor="backgroundColorGray"
              gap={Space300}
            >
              <Box flexDirection="row" justifyContent="space-between">
                <Heading level="h5">{t("build.stack.canvas.staging.scan-qr")}</Heading>

                <Tooltip
                  delayHide={150}
                  trigger={<Icon color="textColorNonessential" name="AnnotationQuestionIcon" size="small" />}
                >
                  <Trans
                    i18nKey="build.stack.canvas.staging.scan-qr-instructions"
                    components={{
                      a: <a href={WhatsAppQRCodeLink} target="_blank" rel="noreferrer" />,
                    }}
                  />
                </Tooltip>
              </Box>

              <Box
                id="qrcode"
                alignItems="center"
                width={184}
                height={184}
                p="100"
                borderRadius="200"
                border="1px solid"
                borderColor="borderColorDefault"
                boxShadow={ShadowHigh}
                mx="auto"
              >
                <QRCodeSVG
                  ref={svgRef}
                  level="H"
                  value={numberWaMeUrl(number)}
                  size={168}
                  fgColor={turnTheme.global.colors["dark-1"]}
                  imageSettings={{
                    src: TurnLogoBase64,
                    excavate: false,
                    height: 60,
                    width: 60,
                  }}
                />
              </Box>

              <Button
                mt="auto"
                variation="flat"
                mx="auto"
                text={t("modals.qr.download")}
                size="xsmall"
                icon={<Icon name="Download_02Icon" />}
                onClick={handleQrDownload}
              />
            </Box>
          </>
        ) : (
          <>
            <Box
              width={330}
              height={376}
              borderRadius={BorderRadius300}
              p={Space300}
              backgroundColor="backgroundColorGray"
              gap={Space300}
            >
              <Heading level="h5">{t("build.stack.canvas.staging.test-unpublished-changes")}</Heading>

              <Box gap="200" mb="auto">
                <Text preset="paragraph1-100">
                  {t("build.stack.canvas.staging.test-unpublished-on")}{" "}
                  <Text
                    color="textColorSubtle"
                    preset="caption100"
                    style={{
                      border: "1px solid",
                      borderColor: BorderColorDefault,
                      borderRadius: BorderRadius200,
                      padding: "2px 4px",
                      backgroundColor: "white",
                    }}
                  >
                    {t("build.stack.canvas.staging.preview-on")}
                  </Text>
                </Text>
                <Text preset="caption100" color="textColorNonessential">
                  {t("build.stack.canvas.staging.test-unpublished-off")}{" "}
                  <Text
                    color="TextColorSubtle"
                    preset="small100"
                    style={{
                      border: "1px solid",
                      borderColor: BorderColorDefault,
                      borderRadius: BorderRadius200,
                      padding: "2px 4px",
                      backgroundColor: "white",
                    }}
                  >
                    {t("build.stack.canvas.staging.preview-off")}
                  </Text>
                </Text>
              </Box>

              <img src={toggleStagingOnWA} alt="" style={{ filter: "drop-shadow(0px 8px 15px #00000014)" }} />
            </Box>

            <Box
              width={330}
              height={376}
              borderRadius={BorderRadius300}
              p={Space300}
              backgroundColor="backgroundColorGray"
              gap={Space300}
            >
              <Heading level="h6" color="textColorNonessential">
                {t("build.stack.canvas.staging.alternatively")}
              </Heading>

              <Box mb="auto">
                <Text preset="caption100">
                  <Trans
                    i18nKey="build.stack.canvas.staging.alternate-step-1"
                    components={{
                      a: <Anchor href={getPath("people")} target="_blank" decoration="underline" />,
                    }}
                  />
                </Text>
                <Text preset="caption100">{t("build.stack.canvas.staging.alternate-step-2")}</Text>
                <Text preset="caption100">{t("build.stack.canvas.staging.alternate-step-3")}</Text>
              </Box>

              <img src={toggleStagingOnTurn} alt="" style={{ filter: "drop-shadow(0px 8px 15px #00000014)" }} />
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};
