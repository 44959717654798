import {
  Box,
  BoxTypes,
  ButtonExtendedProps,
  ButtonType,
  DropButton,
  DropButtonProps,
  Button as GrommetButton,
  Text,
  TextExtendedProps,
} from "grommet";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { colors } from "lib/impact";
import { spacing } from "lib/tokens";
import { turnTheme } from "theme";
import { TActive } from "types";

import { DownArrow } from "../icons/DownArrow";
import { Spinner } from "../loaders";

export const StyledButtonList = styled(Box)`
  button {
    border-radius: 0;
    border-width: 1px;
    border-color: #dce1e7;
    background: transparent;
    color: ${turnTheme.global.colors["dark-4"]};
    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-right: 0;
    }
    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-left: 0;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .btn-active {
    background: ${turnTheme.global.colors.brand};
    border-color: ${turnTheme.global.colors.brand};
    color: ${turnTheme.global.colors.white};
  }
`;

const StyledButtonFilled = styled(GrommetButton)<{
  primary?: boolean;
  warning?: boolean;
  disabled?: boolean;
  black?: boolean;
  backgroundColor?: string;
}>`
  color: white;
  box-shadow: 0px 0 14px rgba(13, 31, 38, 0.14);
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "#00b6bf")};
  border-color: ${({ backgroundColor }) => (backgroundColor ? "none" : "#00b6bf")};
  padding: 16px 28px;
  transition: all 0.15s ease-in;
  &:hover {
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "#00979e")};
    border-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "#00979e")};
  }
  & svg {
    fill: white;
  }
  &:hover {
    color: white;
    box-shadow: 0px 5px 14px rgba(13, 31, 38, 0.08);
  }
  ${(props) => (props.primary ? "padding: 15px 23px;" : "padding: 11px 20px;")}
  /* Button Types */
  ${(props) =>
    props.warning &&
    `background-color: #E9695D; border-color:#E9695D;&:hover {
      background-color: #DB5448;
      border-color: #DB5448;
  }`}
  ${(props) =>
    props.black &&
    `background-color: #000000; border-color:#000000;&:hover {
      background-color: ${colors["dark-2"]};
      border-color: ${colors["dark-2"]};
  }`}
  ${(props) =>
    props.disabled
      ? `
    opacity: 1;
    background-color: #DADADD;
    border-color: #DADADD;
    &:hover {
      opacity: 0.8;
      background-color: #E4E4E7;
      border-color: #E4E4E7;
    }
  `
      : ""}
`;

export const ButtonFilledExtraBig = styled(GrommetButton)<{
  brand?: boolean;
  outline?: boolean;
  outlineIcon?: boolean;
}>`
  height: 56px;
  padding: 14px 32px;
  border-radius: 28px;
  border-width: 2px;
  transition: all 0.15s ease-in;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  ${({ brand, disabled, outline, plain, primary, outlineIcon }) => {
    const { colors } = turnTheme.global;
    const color = disabled
      ? colors.white
      : brand || primary
        ? colors.white
        : outline || plain
          ? colors.brandTone
          : colors.black;
    const background = disabled
      ? colors["light-4"]
      : brand
        ? colors.brandTone
        : primary
          ? colors.black
          : outline || plain
            ? "transparent"
            : colors["accent-3"];
    const hoverBackground = disabled
      ? colors["light-4"]
      : brand
        ? colors.brandTone
        : primary
          ? colors["dark-1"]
          : outline || plain
            ? "transparent"
            : colors["light-3"];

    return `
      color: ${color};
      background: ${background};
      border-color: ${outline ? color : background};

      &:hover {
        color: ${color};
        background: ${hoverBackground};
        border-color: ${outline ? color : hoverBackground};
      }
      
      ${
        !outlineIcon &&
        `& svg {
           fill: ${color};
           
           & path {
             fill: ${color};
           }
         }
        `
      }
    `;
  }}
  ${({ disabled }) => disabled && "opacity: 0.8;"}

  &:hover {
    ${({ plain, disabled }) => !plain && !disabled && "box-shadow: 0px 0px 14px rgba(6, 51, 67, 0.12);"}
  }
`;

const StyledButtonTextIcon = styled(GrommetButton)<{
  color?: string;
  padding?: string;
  outline?: boolean;
  iconSize?: string;
  strokeIcon?: boolean;
}>`
  transition: all 0.15s ease-in;
  color: ${({ color }) => color || turnTheme.global.colors.brandTone};
  border-color: ${({ outline, color }) => (outline ? color || turnTheme.global.colors.brandTone : "transparent")};
  padding: ${({ padding, plain }) => (padding ? padding : plain ? "16px 28px" : "6px 16px")};

  & > div {
    width: max-content;
  }
  & svg {
    transition: inherit;
    ${({ strokeIcon }) => (strokeIcon ? "stroke" : "fill")}: ${({ color }) =>
      color || turnTheme.global.colors.brandTone};
    ${({ iconSize }) => `
      width: ${iconSize ?? "16px"};
      height: ${iconSize ?? "16px"};
    `}

    & path {
      transition: inherit;
      ${({ strokeIcon }) => (strokeIcon ? "stroke" : "fill")}: ${({ color }) =>
        color || turnTheme.global.colors.brandTone};
    }
    & polyline {
      transition: inherit;
      stroke: ${({ color }) => color || turnTheme.global.colors.brandTone};
    }
  }
  &:hover {
    color: ${({ color }) => color || turnTheme.global.colors["dark-3"]};
    border-color: ${({ outline, color }) => (outline ? color || turnTheme.global.colors["dark-3"] : "transparent")};

    & svg {
      ${({ strokeIcon }) => (strokeIcon ? "stroke" : "fill")}: ${({ color }) =>
        color || turnTheme.global.colors["dark-3"]};

      & path {
        ${({ strokeIcon }) => (strokeIcon ? "stroke" : "fill")}: ${({ color }) =>
          color || turnTheme.global.colors["dark-3"]};
      }
      & polyline {
        stroke: ${({ color }) => color || turnTheme.global.colors["dark-3"]};
      }
    }
  }
  ${({ plain }) =>
    plain &&
    `
    background: transparent;
    &:hover {
      background: transparent;
    }
  `}
  &:disabled {
    background: transparent;
    opacity: 1;
    cursor: not-allowed;
    color: ${turnTheme.global.colors["dark-4"]};
    span {
      color: ${turnTheme.global.colors["dark-4"]};
    }
    & svg {
      path,
      rect {
        ${({ strokeIcon }) => (strokeIcon ? "stroke" : "fill")}: ${turnTheme.global.colors["dark-4"]};

        & path {
          ${({ strokeIcon }) => (strokeIcon ? "stroke" : "fill")}: ${turnTheme.global.colors["dark-4"]};
        }
      }
      polyline {
        stroke: ${turnTheme.global.colors["dark-4"]};
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const StyledSearchButton = styled(StyledButtonTextIcon)<{
  padding?: string;
}>`
  color: ${turnTheme.global.colors["dark-4"]};
  border-color: ${turnTheme.global.colors["light-4"]};
  border-radius: 24px;
  font-size: 14px;
  font-weight: normal;
  padding: ${({ padding }) => padding ?? `${spacing.sm + spacing.xxs}px ${spacing.ml}px`};
  line-height: 20px;
  height: 48px;
  background: ${turnTheme.global.colors["light-2"]};

  transition: all 0.15s ease-in;
  & svg {
    fill: ${turnTheme.global.colors["dark-4"]};
    width: 16px;
    height: 16px;
    transition: all 0.15s ease-in;

    & path {
      fill: ${turnTheme.global.colors["dark-4"]};
    }
  }
  &:hover {
    color: ${turnTheme.global.colors["dark-3"]};
    border-color: ${turnTheme.global.colors["dark-4"]};
    background: ${turnTheme.global.colors["light-2"]};

    & svg {
      fill: ${turnTheme.global.colors["dark-3"]};
      & path {
        fill: ${turnTheme.global.colors["dark-3"]};
      }
    }
  }
`;

const StyledButtonText = styled(GrommetButton)`
  color: ${({ color }) => color || turnTheme.global.colors.brandTone};
  border-color: transparent;
  padding: 6px 16px;
  transition: all 0.15s ease-in;
  &:hover {
    color: ${({ color }) => color || turnTheme.global.colors["dark-3"]};
  }
`;

const StyledButtonMute = styled(GrommetButton)<{
  background?: string;
  hoverColor?: string;
  hoverBackground?: string;
}>`
  color: ${({ disabled, color }) => (disabled ? "#fff" : color || "#7a8285")};
  border-color: transparent;
  background-color: ${({ disabled, background }) => (disabled ? "#DADADD" : background || "transparent")};
  font-weight: 400;
  &:hover {
    color: ${({ disabled, hoverColor }) => (disabled ? "#fff" : hoverColor || "#0d1f26")};
    background-color: ${({ disabled, hoverBackground }) =>
      disabled ? "#DADADD" : hoverBackground || "transparent"} !important;
  }
`;

const StyledButtonIcon = styled(GrommetButton)<{ btnSize?: number }>`
  opacity: 0.8;
  padding: 8px;
  background: #f6f8f8;
  border-radius: 100%;
  width: ${({ btnSize = 32 }) => `${btnSize}px`};
  height: ${({ btnSize = 32 }) => `${btnSize}px`};
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: all 0.15s ease-in;
  &:hover {
    opacity: 1;
    background: #ebeded;
  }
  &:disabled {
    &:hover {
      background: ${turnTheme.global.colors["light-2"]};
    }
    cursor: not-allowed;
  }
  ${({ plain }) =>
    plain &&
    `background:transparent;
    &:hover{background:transparent}`}
  ${({ disabled }) => disabled && "opacity:0.5"}
`;

export const BrandButtonIcon = styled(GrommetButton)`
  padding: 8px;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: all 0.15s ease-in;
  border-color: transparent;

  & svg {
    transition: all 0.15s ease-in;
    fill: ${({ color }) => color || turnTheme.global.colors.brandTone};
    width: 16px;
    height: 16px;

    & path {
      fill: ${({ color }) => color || turnTheme.global.colors.brandTone};
    }
  }

  &:hover {
    color: ${({ color }) => color || turnTheme.global.colors["dark-3"]};

    & svg {
      fill: ${({ color }) => color || turnTheme.global.colors["dark-3"]};

      & path {
        fill: ${({ color }) => color || turnTheme.global.colors["dark-3"]};
      }
    }
  }

  &:disabled {
    background: transparent;
    opacity: 1;
    cursor: not-allowed;
    span {
      color: ${turnTheme.global.colors["dark-4"]};
    }
    & svg path {
      fill: ${turnTheme.global.colors["dark-4"]};

      & path {
        fill: ${turnTheme.global.colors["dark-4"]};
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const StyledDoubleButton = styled(GrommetButton)<{
  borderLeftRadius?: string;
  borderRightRadius?: string;
}>`
  border-radius: 0;
  display: flex;
  align-items: center;
  background: ${turnTheme.global.colors.brand};
  color: ${turnTheme.global.colors.white};
  height: 100%;
  border-top-left-radius: ${({ borderLeftRadius }) => borderLeftRadius};
  border-bottom-left-radius: ${({ borderLeftRadius }) => borderLeftRadius};
  border-top-right-radius: ${({ borderRightRadius }) => borderRightRadius};
  border-bottom-right-radius: ${({ borderRightRadius }) => borderRightRadius};
  min-width: max-content;
  &:hover {
    opacity: 0.9;
    background: #3dc5cc;
  }
`;

const StyledDoubleButtonContainer = styled(Box)`
  border-radius: 24px;
  height: 48px;
`;

const StyledLine = styled(Box)`
  height: 100%;
  width: 1px;
  background: #fff;
`;

export const StyledListButton = styled(GrommetButton)`
  padding: 10px 16px;
  background: #fff;
  border-radius: 4px;
  white-space: nowrap;
  & > div {
    justify-content: start;
  }
`;

export const StyledButtonIconMute = styled(GrommetButton)`
  opacity: 0.8;
  padding: 8px;
  background: transparent;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: all 0.15s ease-in;

  &:hover {
    opacity: 1;
    background: #f6f8f8;
  }
  ${(props) =>
    props.plain &&
    `background:transparent;
    &:hover{background:transparent}`}
`;

export const StyledOrderingDropButton = styled(DropButton)`
  border-radius: 24px;
  margin-left: 8px;
  font-size: 13px;
  line-height: 24px;
  &:hover {
    color: ${turnTheme.global.colors["dark-3"]};
    background: ${turnTheme.global.colors["light-2"]};
  }
`;

const StyledOrderingDropButtonBig = styled(DropButton)`
  border-radius: 28px;
  font-size: 15px;
  line-height: 19px;
  padding: 13px 16px;
  ${turnTheme.global.colors["dark-1"]};
  svg {
    transition:
      fill,
      stroke 0.1s ease-in-out;
    fill: ${turnTheme.global.colors["dark-1"]};
    stroke: ${turnTheme.global.colors["dark-1"]};
    path {
      fill: ${turnTheme.global.colors["dark-1"]};
    }
  }
  &:hover {
    color: ${turnTheme.global.colors["dark-2"]};
    background: ${turnTheme.global.colors["light-2"]};
    svg {
      fill: ${turnTheme.global.colors["dark-2"]};
      stroke: ${turnTheme.global.colors["dark-2"]};
      path {
        fill: ${turnTheme.global.colors["dark-2"]};
      }
    }
  }
`;

const IconWrapper = styled(Box)<{
  hoverColor?: string;
}>`
  ${(props) => {
    const hoverColor = props.hoverColor || turnTheme.global.colors["dark-4"];

    return (
      (props.color || props.onClick) &&
      `svg {
        transition: all 0.15s ease-in;
        ${props.color ? `fill: ${props.color};` : ""}
 
        path {
          ${props.color ? `fill: ${props.color};` : ""}
        }
      }

      ${
        props.onClick
          ? `
        &:hover {
          svg {
            fill: ${hoverColor};
    
            path {
              fill: ${hoverColor};
            }
          }
        }
        `
          : ""
      }
      `
    );
  }}
`;

export const ReplyBoxStyledButton = styled.span<TActive>`
  color: ${({ active }) => (active ? turnTheme.global.colors.brand : turnTheme.global.colors["dark-3"])};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  font-family: "Menlo", "Monaco", "Andale Mono", "Courier New", monospace;
  font-weight: ${({ active }) => (active ? "bold" : "300")};
  margin-bottom: -6px;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1.0)};
  &:hover {
    opacity: 0.4;
  }
  padding: 0 4px 4px 4px;
`;

/* COMPONENTS
------------------------------------------ */

export const OrderingDropButtonBig = (props: DropButtonProps & TButtonType) => (
  <StyledOrderingDropButtonBig gap="8px" reverse {...props} />
);

export const OrderingDropButton = (props: DropButtonProps & TButtonType) => (
  <StyledOrderingDropButton icon={<DownArrow />} gap="16px" reverse {...props} />
);

interface IButtonFilledBig extends TButtonType {
  black?: boolean;
  warning?: boolean;
}

export const ButtonFilledBig = ({ black, warning, ...props }: IButtonFilledBig) => (
  <StyledButtonFilled black={black} warning={warning} {...props} />
);

export const ReplyButton = ({ ...props }: IButtonFilledBig) => <ButtonFilledBig reverse primary {...props} />;

export interface IButtonTextIcon extends ButtonType {
  outline?: boolean;
  padding?: string;
  strokeIcon?: boolean;
}
export const ButtonTextIcon = (props: IButtonTextIcon) => <StyledButtonTextIcon {...props} />;

export const ButtonTextIconSmall = ({ label, ...props }: IButtonTextIcon) => (
  <StyledButtonTextIcon
    label={<Text size="12px">{label}</Text>}
    iconSize="12px"
    gap="8px"
    padding="6px 16px"
    {...props}
  />
);

interface IButtonIcon extends Omit<ButtonType, "size"> {
  size?: number;
}
export const ButtonIcon = ({ size, ...props }: IButtonIcon) => (
  <StyledButtonIcon btnSize={size} {...props} gap="none" />
);

export const ButtonIconMute = (props: TButtonType) => <StyledButtonIconMute {...props} gap="none" />;

export const ButtonTextMute = (props: TButtonType) => <StyledButtonMute gap="none" {...props} />;

export const ButtonText = (props: TButtonType) => <StyledButtonText {...props} gap="none" />;

interface IDoubleButton {
  buttons: React.ReactNode[];
}

type TDoubleButtonProps = IDoubleButton & TButtonType;

export const DoubleButton = ({ buttons, ...props }: TDoubleButtonProps) => (
  <StyledDoubleButtonContainer direction="row">
    <StyledDoubleButton {...props} borderLeftRadius="24px" borderRightRadius={buttons.length > 0 ? "0" : "24px"} />
    {buttons.map((button, index) => (
      <Box direction="row" key={index}>
        <StyledLine />
        {button}
      </Box>
    ))}
  </StyledDoubleButtonContainer>
);

/* 
A thing that looks like a button but is not, can be used in dropdown
menus where react complains about buttons not being able to be children
of other buttons
*/

interface IFakeButton {
  label: string;
  icon?: React.ReactNode;
  iconProps?: {
    hoverColor?: string;
  };
}

type TFakeButtonProps = IFakeButton & BoxTypes;

export const FakeButton = ({ label, icon, iconProps, ...props }: TFakeButtonProps) => {
  return (
    <Box
      flex
      fill
      direction="row"
      pad={{
        // these are the same as DropMenuBtn
        left: "24px",
        right: "24px",
        top: "8px",
        bottom: "8px",
      }}
      style={{
        fontWeight: 400,
        fontSize: "14px",
      }}
      {...props}
    >
      <Box fill alignSelf="start">
        <Text
          style={{
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          {label}
        </Text>
      </Box>
      {icon && (
        <IconWrapper fill="vertical" pad={{ top: "xsmall", bottom: "xsmall" }} {...iconProps}>
          {icon}
        </IconWrapper>
      )}
    </Box>
  );
};

export type TButtonType = ButtonExtendedProps & JSX.IntrinsicElements["a"];

interface IButton extends TButtonType {
  loading?: boolean;
  pad?: string;
  className?: string;
  width?: string;
  height?: string;
  round?: string;
  backgroundColor?: string;
}

export const Button = ({
  loading,
  pad,
  width,
  height,
  label,
  className,
  round,
  backgroundColor,
  style,
  ...props
}: IButton) => {
  const { t } = useTranslation("common");

  return (
    <GrommetButton
      style={{ padding: pad, width, height, borderRadius: round, backgroundColor, ...style }}
      className={className}
      {...props}
      label={
        loading ? (
          <Box direction="row" align="center">
            <Spinner size={(props.size === "small" && 8) || 12} />
            <Text size={props.size || "medium"} color={props.color} margin={{ left: "8px" }}>
              {t("global.loading")}
            </Text>
          </Box>
        ) : (
          label
        )
      }
    />
  );
};

interface IButtonTextIconBig extends IButtonTextIcon {
  labelProps?: TextExtendedProps;
}

export const ButtonTextIconBig = ({ label, labelProps, ...props }: IButtonTextIconBig) => (
  <ButtonTextIcon
    label={
      <Text size="xlarge" weight={600} {...labelProps}>
        {label}
      </Text>
    }
    {...props}
  />
);

const StyledButton = styled(GrommetButton)<{ primary?: boolean; color?: string; full?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  background: #f6f8f8;
  border-radius: 28px;
  width: ${({ full }) => (full ? "100%" : "fit-content")};
  height: 48px;
  ${({ primary, color }) =>
    !primary
      ? `
    background: #00B6BF;
    color: ${turnTheme.global.colors[color] || "#fff"};
    &:hover {
      background: #00979E;
    }
    &:disabled {
      background: #DADCDD;
    }
  `
      : `
    color: ${turnTheme.global.colors[color] || "#929A9E"};
    background: transparent;
    border: none;
    &:hover {
      background: #F6F8F8;
      border: none;
      color: ${turnTheme.global.colors[color] || "#929A9E"};
    }
    &:disabled {
      background: #DADCDD;
    }
  `};
`;

interface IButtonDesignProps extends TButtonType {
  full?: boolean;
  labelProps?: TextExtendedProps;
}

export const ButtonDesign = ({ label, labelProps, ...props }: IButtonDesignProps) => {
  const text = label ? (
    <Text weight={!props.primary ? 600 : 300} size="15px" style={{ lineHeight: "19px" }} {...labelProps}>
      {label}
    </Text>
  ) : undefined;
  return <StyledButton label={text} {...props} />;
};
