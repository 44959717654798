import styled from "styled-components";
import { space } from "styled-system";

import { Button } from "ds/Button";
import {
  ColorNeutralBlack,
  FontFamilyDMSans,
  Space50,
  Space100,
  Space150,
  Space200,
  Space250,
  Space300,
  SpaceNone,
} from "ds/Core";
import { FormColorTextPlaceholder, FormColorTextPlaceholderFocus } from "ds/Form";

import { getTextInputStyles, theme } from "./theme";
import { InputSize, StyledInputProps } from "./types";

export const StyledInputContainer = styled.div<{ inputSize: InputSize; height?: any }>`
  position: relative;
  display: flex;
  height: ${({ inputSize, height }) => height || theme.size[inputSize].height};

  input[type="time"]:not(:valid) {
    color: ${FormColorTextPlaceholder};
  }
`;

export const StyledIconLeft = styled.div<{ inputSize: InputSize; flat?: boolean }>`
  position: absolute;
  left: ${({ inputSize }) => (inputSize === "xsmall" ? Space100 : inputSize === "large" ? Space250 : Space200)};
  top: 50%;
  transform: translateY(-50%);
  height: ${({ inputSize }) => (inputSize === "xsmall" ? Space200 : inputSize === "large" ? Space300 : Space250)};
  width: ${({ inputSize }) => (inputSize === "xsmall" ? Space200 : inputSize === "large" ? Space300 : Space250)};

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke: ${ColorNeutralBlack};
    }
  }

  ${({ flat }) =>
    flat &&
    `
      left: 0;
    `}
`;

export const StyledIconRight = styled.div<{ inputSize: InputSize }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ inputSize }) => (inputSize === "xsmall" ? Space100 : inputSize === "large" ? Space250 : Space200)};
  display: flex;
  align-items: center;
`;

export const StyledClearButton = styled(Button)<{ inputSize: InputSize; flat?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ inputSize, flat }) => (inputSize === "large" ? Space100 : flat ? SpaceNone : Space50)};
`;

export const StyledInput = styled.input<StyledInputProps>`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-family: ${FontFamilyDMSans};

  &::-webkit-search-cancel-button {
    display: none;
  }

  &::placeholder {
    color: ${FormColorTextPlaceholder};
  }

  &:focus {
    &::placeholder {
      color: ${FormColorTextPlaceholderFocus};
    }
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  ${(props) => getTextInputStyles(props)}
  padding-right: 60px;

  ${({ iconLeft, flat, inputSize }) =>
    iconLeft &&
    flat &&
    `
    padding-left: calc(${inputSize === "xsmall" ? Space200 : inputSize === "large" ? Space300 : Space250} + ${Space150})
  `}

  ${space}
`;
