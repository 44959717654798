import { FormNext, FormPrevious } from "grommet-icons";
import { grommet } from "grommet/es6/themes";
import { deepMerge } from "grommet/es6/utils";

import { ArrowDropDownIcon, ArrowDropUpIcon } from "lib/icons/Arrow";
import { FormCheckmarkIcon } from "lib/icons/CheckMarkIcon";
import { DownArrow } from "lib/icons/DownArrow";

export const turnTheme = deepMerge(grommet, {
  global: {
    colors: {
      sky: "#4795E0",

      brand: "#00B6BF",
      brandBright: "#00C2CC",
      brandTint: "#F5FEFF",
      brandTone: "#00979E",
      white: "#ffffff",
      error: "#DB4B50",
      black: "#000000",

      "brand-dark": "#00979E",

      "accent-1": "#68A33B",
      "accent-1-tint": "#F6A476",
      "accent-2": "#E96B41",
      "accent-2-tint": "#FFF4F3",
      "accent-3": "#F3F4F5",
      "accent-3-tint": "#FFF8EA",

      "label-1": "#DD5D52",
      "label-1-tint": "#FFF4F3",
      "label-2": "#E8A004",
      "label-2-tint": "#FFF8EA",
      "label-3": "#1B82CC",
      "label-3-tint": "#F0F9FF",
      "label-4": "#8155A1",
      "label-4-tint": "#FAF4FF",
      "label-5": "#E25F84",
      "label-5-tint": "#FFF5F8",
      "label-6": "#E87F47",
      "label-6-tint": "#FFF3EC",
      "label-7": "#99AD35",
      "label-7-tint": "#F6FAE1",
      "label-8": "#0D1F26",
      "label-8-tint": "#F6F8F8",
      "label-9": "#10A86E",
      "label-9-tint": "#E9FFF6",

      "light-1": "#FAFCFC",
      "light-2": "#F6F8F8",
      "light-3": "#E7EAEB",
      "light-4": "#DADCDD",
      "light-5": "#BABEC0",
      "light-6": "#D8D8D8",

      "dark-1": "#0D1F26",
      "dark-2": "#535C5F",
      "dark-3": "#7A8285",
      "dark-4": "#929A9E",

      "status-unknown": "#BABEC0",
      "status-critical": "#F55359",
      "status-warning": "#FFBF1F",
      "status-ok": "#00C78B",

      mute: "light-3",
      border: "light-3",
      placeholder: "#929A9E",

      text: {
        light: "dark-1",
      },
      custom: {
        grey: "#7A8184",
        "grey-2": "#535C5F",
        dark: "#08415C",
        light: "#DADADA",
        "light-1": "#C4C4C4",
        "light-2": "#CCF6FB",
        "light-3": "#7A8285",
        "light-4": "#F7FEFF",
        "light-5": "#DEFAFD",
        "light-6": "#E3DDD7",
        "light-7": "#E8FDFF",
        "light-8": "#CACDCE",
        wa: "#3F95FF",
        "accent-3": "#F3F4F5",
        orange: "#FEDF7A",
        teal: "#76D7CA",
        green: "#82E7C5",
        blue: "#86C1FC",
        lavender: "#BD98D2",
        lime: "#B2FC82",
        pink: "#D4B6FF",
        // todo (alexandrchebotar, 2022-09-15): remove when the Design system PR will be merged
        "blue-700": "#0072F3",
      },
    },

    borders: {
      brand: "2px solid #00B6BF",
      "accent-1": "2px solid #6BA043",
      "accent-2": "2px solid #E96B41",
      "accent-3": "2px solid #F3F4F5",
      outline: "1px solid #929A9E",
    },

    control: {
      border: {
        width: "1px",
        radius: "5px",
        color: "border",
      },
    },

    font: {
      family: "'DM Sans', sans-serif",
      size: "14px",
      height: "20px",
    },

    size: {
      avatarxxSmall: "24px",
      avatarxSmall: "32px",
      avatarSmall: "40px",
      avatarMedium: "80px",
      avatarLarge: "120px",
    },

    pad: {
      small: "16px",
    },

    input: {
      weight: "normal",
      padding: "15px",
    },

    focus: {
      border: {
        color: "none",
      },
      extend: () => "box-shadow: 0 0 2px 2px red;",
    },

    drop: {
      border: {
        radius: "5px",
      },
      shadowSize: "small",
    },
    selected: {
      background: "brandTint",
      color: "dark-1",
    },

    edgeSize: {
      none: "0px",
      hair: "1px",
      xsmall: "4px",
    },

    elevation: {
      light: {
        none: "none",
        medium: "0px 0 17px rgba(13, 31, 38, 0.08)",
        large: "0px 4px 10px rgba(13, 31, 38, 0.08)",
      },
    },
    rangeInput: {
      track: {
        height: "3px",
      },
    },
    breakpoints: {
      small: {
        value: 768,
      },
      medium: {
        value: 992,
      },
      lmedium: {
        value: 1100,
      },
      large: {
        value: 1200,
      },
      xlarge: {
        value: 1366,
      },
      xxlarge: {
        value: 1440,
      },
    },
  },

  chart: {
    extend: () => "color: blue",
  },

  anchor: {
    fontWeight: 500,
    color: {
      dark: "accent-1",
      light: "brandTone",
    },
    hover: {
      //textDecoration: "underline" // fontWeight: undefined,
      // extend: undefined,
    }, // extend: undefined,
  },

  accordion: {
    border: {
      side: "bottom",
      color: "#EBEEF0",
    },
    // level ranges from 1-6
    icons: {
      collapse: ArrowDropUpIcon,
      expand: ArrowDropDownIcon,
    },
  },

  button: {
    border: {
      width: "1px",
      color: "transparent",
      radius: "32px",
    },
    padding: {
      vertical: "6px",
      horizontal: "16px",
    },
    extend: (props) => {
      let primaryStyles = "";
      let secondaryStyles = "";
      if (props.primary) {
        primaryStyles = `
          border-color: transparent;
          color: white;
          font-weight:600;
          &:hover {
            color: white;
            border-color: #35B5BB;
            background-color: #35B5BB;
          }
        `;
      }
      if (props.secondary) {
        secondaryStyles = `
          border-radius: 32px;
          padding: 0 16px;
          background-color: transparent;
          font-weight:600;
        `;
      }
      return `
        font-weight:500;
        font-size: 15px;
        border-color: transparent;
        &:hover {
          box-shadow: none;
          ${!props.plain && "background-color: #F6F8F8;"}
        }
        ${primaryStyles}
        ${secondaryStyles}
      `;
    },
  },
  calendar: {
    day: {
      extend: ({ isSelected }) => `
      ${isSelected && "background-color:#00B6BF; color: white; font-size: 14px"}`,
      // extend: () => `border-radius:100%`
    },
    icons: {
      next: FormNext,
      previous: FormPrevious,
    },
    extend: () =>
      "width: 282px; height: 338px; padding: 16px; border: 1px solid #DADCDD; border-radius :5px; box-shadow: 0px 0 17px rgba(13, 31, 38, 0.08); button > div {}.StyledCalendar__StyledDay-sc-1y4xhmp-5{width: 40px; height:40px; font-size: 14px;};.StyledHeading-sc-1rdh4aw-0{font-weight: 500; font-size:15px}",
  },
  checkBox: {
    border: {
      width: "2px",
      color: {
        light: "light-5",
      },
    },
    size: "20px",
    check: {
      radius: "3px",
      thickness: "3px",
      extend: ({ checked }) => `
        ${checked && "background-color: #00B6BF; border-color: #00B6BF;"},
      `,
    },

    extend: () => `
      input:checked + span > span {
        left: calc(32px - 16px) !important;
      }
    `,

    icons: {
      checked: FormCheckmarkIcon,
    },

    icon: {
      size: "16px",
    },

    hover: {
      border: {
        color: {
          dark: "white",
          light: "brandTone",
        },
      },
      extend: () => `
        background-color: #00ABB3;
      `,
    },

    toggle: {
      color: {
        light: "#DADCDD",
      },
      background: {
        light: "#DADCDD",
      },
      size: "32px",
      knob: { extend: () => "border:2px solid #DADCDD;background-color:white" },

      extend: () =>
        `
          border-color: #DADCDD;
          height: 16px;
          > span {
            width: 16px;
            height: 16px;
          }
          &:hover > span:not([disabled]) {
            border-color: #00B6BF;
            background:#00B6BF;
          };
        `,
    },
  },

  radioButton: {
    border: {
      color: {
        light: "#00ABB3",
      },
      width: "2px",
    },

    check: {
      radius: "100%",
      extend: ({ checked }) => `
        ${checked && "background-color: #00B6BF; border-color: #00B6BF; > svg{stroke:white}"},
      `,
    },
    hover: {
      border: {
        color: {
          light: "brandTone",
        },
      },
    },
    icon: {
      size: "10px",
    },
    icons: {
      // circle: undefined,
    },
    gap: "small",
    size: "18px",
  },

  formField: {
    border: false,
    content: {
      pad: {
        horizontal: "small",
        bottom: "small",
      },
    },
    error: {
      color: {
        dark: "status-critical",
        light: "status-critical",
      },
      margin: {
        vertical: "xsmall",
        horizontal: "0",
      },
    },
    // TODO: Remove input border style overrides. Consider adding custom input styles.
    extend: () => `
      input {
        border: 1px solid #DADCDD;
        &:disabled {
          border: none;
        }
      }
      textarea {border: 1px solid #DADCDD}

      &:hover > input {border:1px solid #BABEC0}
      &:hover > button {border:none; background-color: transparent;}
    `,
    help: {
      color: {
        dark: "dark-3",
        light: "dark-3",
      },
      margin: {
        left: "none",
      },
    },
    label: {
      weight: 500,
      margin: {
        vertical: "6px",
        horizontal: "0",
      },
      color: {
        light: "dark-2",
      },
    },
    margin: {
      bottom: "small",
    },
  },

  heading: {
    font: {
      // family: undefined
    },
    level: {
      1: {
        medium: {
          size: "32px",
        },
      },
      2: {
        medium: {
          size: "24px",
        },
      },
      3: {
        medium: {
          size: "18px",
        },
      },
      4: {
        medium: {
          size: "16px",
        },
      },
      5: {
        medium: {
          size: "14px",
        },
      },
      6: {
        font: { weight: 500, "letter-spacing": "0.1em " },
        medium: {
          size: "12px",
        },
      },
    },
    weight: 600,
  },

  icon: {
    size: {
      xsmall: "11px",
      small: "16px",
      medium: "24px",
    },
  },

  input: {
    weight: 400,
  },

  paragraph: {
    medium: {
      size: "16px",
    },
    large: {
      size: "18px",
    },
    xlarge: {
      size: "22px",
    },
  },

  tabs: {
    gap: "medium",
    background: "white",
    panel: {
      extend: () => "margin-top:12px",
    },
  },
  tab: {
    color: "dark-3",
    background: "none",
    extend: () =>
      `font-weight: 600; margin-top:0;
      span {
        font-size: 16px;
      };margin-bottom:0`,
    active: {
      color: "dark-1",
    },
    border: {
      size: "3px",
      color: {
        light: "transparent",
      },
      active: {
        color: {
          light: "brand",
        },
        size: "6px",
      },
    },
    pad: {
      bottom: "2px",
      horizontal: undefined,
    },
    margin: {
      horizontal: undefined,
    },
    hover: {
      background: "white",
      extend: () =>
        `
        border-color: #00B6BF;
        `,
    },
  },

  text: {
    xsmall: {
      size: "11px",
      height: "12px",
    },
    small: {
      size: "12px",
    },
    medium: {
      size: "13px",
    },
    large: {
      size: "14px",
      height: "20px",
    },
    xlarge: {
      size: "16px",
    },
    xxlarge: {
      size: "24px",
    },
  },

  table: {
    header: {
      extend: () => `
        font-size:13px`,
    },
    body: {
      align: "start",
      pad: {
        horizontal: "small",
        vertical: "small",
      },
      extend: () => `
        font-size:13px;
        min-height: 48px;
        border-bottom: 1px solid #EBEEF0;
      `,
    },
    footer: {
      align: "start",
      pad: {
        horizontal: "small",
        vertical: "xsmall",
      },
      border: "top",
      verticalAlign: "top",
      fill: "vertical", // extend: undefined,
    },
  },

  textInput: {
    // placeholder: {
    //   extend: () => `color: #44444`
    // }
    extend: () => `&:focus {
      border: 1px solid #BABEC0;
    }`,
    border: {
      radius: "4px",
      color: "light-4",
    },
  },

  textArea: {
    // placeholder: {
    //   extend: () => `color: #44444`
    // }
    extend: () => `
    
  &:focus {
      border: 1px solid #BABEC0
    }`,
  },

  select: {
    borderRadius: "4px",
    container: {
      extend: () => 'border: "1px solid blue"',
    },
    hover: {
      background: "transparent",
    },
    control: {
      // extend: undefined,
      extend: () => `
      border: 1px solid #DADCDD;
      border-radius: 5px;
      input {
        border: none;
        &:focus, &:hover, &:active {
          border: none;
        }
      }
      &:hover{
        border: 1px solid #DADCDD
      }
      `,
    },
    icons: {
      // color: { dark: undefined, light: undefined },
      margin: {
        horizontal: "small",
      },
      down: DownArrow,
    },
    options: {
      container: {},
      box: {
        align: "start",
        pad: "small",
      },
      text: {
        margin: "none",
        extend: () => `
          font-weight: 400;
          font-size: 16px;
        `,
      },
    },
    step: 20,
  },

  meter: {
    color: "brand",
  },

  // some added transtitions with default easing method, who knows might be handy to expand on.
  transition: {
    slight: "0.15s ease-in-out",
  },

  collapsible: {
    minSpeed: "400",
  },
});
